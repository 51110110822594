// Картинки
// import uniLogo from './images/gmpi-logo.png';
import uniLogo from './images/tsu_logo.png';
import faceelook from './images/faceelook.png';

// Основные переменные
// export const baseUrl = 'https://78.140.15.84:8443/';
export const baseUrl = 'https://uface.su/';
// export const baseUrl = 'https://uface.ippolitovka.ru/';
export const InstCode = 3;

// Константы для трансформации
export const FaceELookLogo = faceelook;
export const UniversityLogo = uniLogo;

export const TextEnterUniversity = 'ТГУ';
// export const TextEnterUniversity = 'Ипполитовка';
export const universityPd = 'tsu'; //tsu gmpi
// export const universityUrl = 'https://uface.ippolitovka.ru/';
export const universityUrl = 'https://uface.su/';

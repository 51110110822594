import React, { useEffect, useState } from 'react';
import Frame from '../../images/Frame.svg';
import Button from '../../components/Button/Button';
import css from './Success.module.css';

import securicaLogo from '../../images/sekurika_logo.svg';
import faceelook from '../../images/faceelook_eye_itog.png';

// eslint-disable-next-line
import Back from '../../components/Back/Back';

const Success = () => {
  const [email, setEmail] = useState('');

  useEffect(() => {
    if (sessionStorage.getItem('recoverData')) {
      sessionStorage.setItem(
        'emailSuccess',
        JSON.parse(sessionStorage.getItem('recoverData')).email
      );
      setEmail(sessionStorage.getItem('emailSuccess'));
      sessionStorage.removeItem('recoverData');
      sessionStorage.removeItem('recoverPhoto');
    }

    if (sessionStorage.getItem('emailSuccess')) {
      setEmail(sessionStorage.getItem('emailSuccess'));
    }
  }, []);

  return (
    <div className={css.base_container}>
      {/* <Back
        to='/registration'
        onclick={() => {
          localStorage.clear();
          sessionStorage.clear();
        }}
      /> */}
      <div className={css.header}>Анкета успешно заполнена</div>
      <div className={css.header}>Спасибо за посещение нашего стенда</div>
      <div className={css.content}>
        <center>
          <div
            style={{
              marginTop: 41,
              marginBottom: 41,
              width: 111,
              height: 111,
              borderRadius: 128,
              backgroundColor: '#247ABF',
            }}
          >
            <img
              style={{ width: 48, height: 65, marginTop: 24 }}
              src={Frame}
              alt='ok'
            />
          </div>
        </center>
        <div className={css.label1}>
          <label>
            На указанную Вами почту будет направленно письмо с рекламными
            материалами.
          </label>
          <br />
        </div>
        <div className={css.logoBottom}>
          <div>
            <img src={securicaLogo} height={50} alt='uni-ubi logo' />
          </div>
          <div>
            <img src={faceelook} height={50} alt='face-elook logo' />
          </div>
        </div>
        <Button
          onclick={() => {
            localStorage.clear();
            sessionStorage.clear();
          }}
          to='/registration'
        >
          Далее
        </Button>
      </div>
    </div>
  );
};

export default Success;
